import {
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";

import {
  getChartColor,
  getCategoryLabel,
  getCategoryCardCOlor,
} from "../utils/chartData";
import SvgIcon from "@material-ui/core/SvgIcon";

const MainStatistics = ({ detectionStats }) => {
  const categories = ["car", "bus", "truck", "bicycle", "motorcycle"];

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      justify='center'
      align='center'
      sx={{ flexGrow: 1 }}
      spacing={3}
    >
      <Grid item>
        <Card sx={{ backgroundColor: "GoldenRod" }}>
          <AssessmentIcon style={{ fontSize: 80, color: "White" }} />
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Havainnot Yhteensä
            </Typography>
            <Typography variant='h4' color='textSecondary' component='p'>
              {detectionStats["totalDetections"]}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item>
        <Box>
          <Grid
            container
            spacing={2}
            sx={{ flexGrow: 1 }}
            align='center'
            alignItems='center'
          >
            {categories.map((category) => (
              <Grid item key={category}>
                <Card sx={{ backgroundColor: "DarkOrange" }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant='h8'
                      component='h2'
                      align='center'
                      color='Black'
                    >
                      {getCategoryLabel(category)}
                    </Typography>
                    <Typography
                      variant='h4'
                      color={getChartColor(category)}
                      component='p'
                      align='center'
                      fontWeight='fontWeightBold'
                    >
                      {detectionStats[category]}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MainStatistics;
