import { Doughnut } from "react-chartjs-2";
import { Box } from "@material-ui/system";
import Paper from "@material-ui/core/Paper";
import ChartDataLabels from 'chartjs-plugin-datalabels';



  
const options = {
  plugins: {
    datalabels: {
      backgroundColor: function(context) {
        return context.dataset.backgroundColor;
      },
      borderColor: 'white',
      borderRadius: 25,
      borderWidth: 2,
      color: 'white',
      display: true,
      font: {
        weight: 'bold'
      },
      padding: 6,
      formatter: Math.round
    }
  },

  // Core options
  aspectRatio: 4 / 3,
  cutoutPercentage: 32,
  layout: {
    padding: 32
  },
  elements: {
    line: {
      fill: false
    },
    point: {
      hoverRadius: 7,
      radius: 5
    }
  },
};


const StatsChart = ({ statsChartData }) => (
  <div>
    <Box m={1.2} width="50%" mx="auto">
      <Paper>
        <Doughnut data={statsChartData} options={options} plugins={[ChartDataLabels]}/>
      </Paper>
    </Box>
  </div>
);

export default StatsChart;
