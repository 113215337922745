import { React, useState, useRef, useEffect } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Container, Grid, Divider, Card } from "@material-ui/core";

import { Box } from "@material-ui/system";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";


import MainStatistics from "./MainStatistics";

import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DateTimePicker from "@material-ui/lab/DateTimePicker";
import TextField from "@material-ui/core/TextField";

import TimelineIcon from "@material-ui/icons/Timeline";

import RefreshIcon from "@material-ui/icons/Refresh";



import LineChart from "./Chart";
import StatsChart from "./statsChart";

import { getDataTimeRange } from "../api";
import { format } from "date-fns";

import { formatChartData, formatStatsChartData } from "../utils/chartData";



function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function startDate() {
  const rangeStart = new Date();
  rangeStart.setDate(rangeStart.getDate() - 1);
  return rangeStart;
}

export default function ButtonAppBar(props) {
  const [startValue, setStartValue] = useState(startDate);
  const [endValue, setEndValue] = useState(new Date());
  const [chartData, setChartData] = useState({});
  const [detectionStats, setDetectionStats] = useState({});
  const [statsChartData, setStatsChartData] = useState({});

  const [showTimeline, setShowTimeline] = useState(true);
  const [connectionError, setConnectionError] = useState(false);
  const [connectionErrorMessage, setConnectionErrorMessage] = useState("");
  

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    if(newValue === 0) setShowTimeline(true);
    else if ( newValue === 1) setShowTimeline(false);
  };

  
  

  useEffect(() => {
    
    refreshChartData();
  }, []);

  const startDateChange = (newValue) => {
    // Sat Jul 24 2021 17:44:51 GMT+0500 (Pakistan Standard Time)
    setStartValue(newValue);
  };

  const endDateChange = (newValue) => {
    setEndValue(newValue);
  };

  const refreshDataHandler = (responseObject) => {

    const formattedChartData = formatChartData(responseObject.data);
    setChartData(formattedChartData);
    setDetectionStats(responseObject.data["categoryStats"]);
    setStatsChartData(
      formatStatsChartData(responseObject.data["categoryStats"])
    );

    setConnectionError(false);
    console.log(responseObject.data);
  };
  const errorHandler = (errorObject) => {
    setConnectionError(true);
    if (!errorObject.response) {
      // network error
     setConnectionErrorMessage( 'Network Error');
  } else {
    setConnectionErrorMessage(errorObject.response.data.message);
  }
    
  };

  const refreshChartData = () => {
    

    

    getDataTimeRange(
      startValue,
      endValue,
      [],
      refreshDataHandler,
      errorHandler
    );
  };

  const mainStatistics = { total: 200 };

  return (
    <div sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Vireum Vision
          </Typography>
          <Button color="inherit" onClick={() => { props.logout()  }} >Logout</Button>
        </Toolbar>
      </AppBar>

      {connectionError && <Card sx={{color:"Gainsboro"}}><Typography variant="h6" sx={{color: "Red"}}> Error : {connectionErrorMessage} </Typography></Card> }

      <Container sx={{ marginTop: "20px" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid
            container
            justifyContent="center"
            justify="center"
            sx={{ flexGrow: 1 }}
            alignItems="center"
          >
            <Grid item>
              <Box m={1}>
                <DateTimePicker
                  color="primary"
                  renderInput={(props) => <TextField {...props} />}
                  label="Alkuaika"
                  value={startValue}
                  onChange={(newValue) => {
                    setStartValue(newValue);
                  }}
                />
              </Box>
            </Grid>
            <Grid item justify="center">
              <Box m={1}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Loppuaika"
                  value={endValue}
                  onChange={(newValue) => {
                    setEndValue(newValue);
                  }}
                />
              </Box>
            </Grid>
            {/* <Grid item  >
            <Box m={1.2}>   
              <IconButton >
                <FilterListIcon fontSize="large" />
              </IconButton>
              </Box> 
            </Grid> */}

            <Box m={1.2}>
              <IconButton onClick={() => refreshChartData()}>
                <RefreshIcon fontSize="large" color="primary" />
              </IconButton>
            </Box>


          </Grid>
          <Divider />
          <Box sx={{ flexGrow: 1, marginTop: "10px" }}>
            <MainStatistics detectionStats={detectionStats} />
          </Box>
          <Box m={1.2}>
            
          

          <Paper square sx={{flexGrow:1}}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="Choose stats"
            >
              <Tab icon={<TimelineIcon />} label="Aikajana" />
              <Tab icon={<DonutSmallIcon />} label="Statistiikka" />
              
            </Tabs>
          </Paper>
          </Box>

          
            {showTimeline ? <LineChart chartData={chartData}/> : <StatsChart statsChartData={statsChartData} /> }
           
          

          {/* 
              <LineChart chartData={chartData}/>
            */}
          {/* <StatsChart statsChartData={statsChartData} /> */}
        </LocalizationProvider>
      </Container>
    </div>
  );
}
