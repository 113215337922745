const supportedCategories = ["car", "bus", "truck", "bicycle", "motorcycle"];

const chartColors = {
  car: "rgba(100,10,10,1)",
  bus: "rgba(255,10,110,1)",
  truck: "MidnightBlue",
  bicycle: "rgba(100,100,0,1)",
  motorcycle: "SeaGreen",
};

const categoryCardColors = {
  car: "LightSkyBlue",
  bus: "LightSteelBlue",
  truck: "MediumTurquoise",
  bicycle: "PaleTurquoise",
  motorcycle: "PowderBlue",
};

export function getCategoryCardCOlor(category) {
  return categoryCardColors[category];
}

const chartCategoryLabels = {
  car: "Auto",
  bus: "Linja-auto",
  truck: "Kuorma-auto",
  bicycle: "Polkupyörä",
  motorcycle: "Moottoripyörä",
};

export function getCategoryLabel(category) {
  return chartCategoryLabels[category];
}

function getDatasetByCategory(categoryDatasets, category) {
  for (var index in categoryDatasets) {
    if (categoryDatasets[index]["label"] == category) {
      return categoryDatasets[index]["data"];
    }
  }
}

const rand255 = () => Math.round(Math.random() * 255);

export function formatChartData(inputData) {
  const formattedChartData = supportedCategories.map((categoryName) => {
    return {
      label: chartCategoryLabels[categoryName],
      borderColor: getChartColor(categoryName),
      backgroundColor: getChartColor(categoryName),
      pointBorderColor: getChartColor(categoryName),
      pointBackgroundColor: getChartColor(categoryName),
      pointBorderWidth: 1,

      data: getDatasetByCategory(inputData["categoryDatasets"], categoryName),
    };
  });
  return { datasets: formattedChartData };
}

export function getChartColor(chartType) {
  return chartColors[chartType];
}

export function formatStatsChartData(inputStats) {
  var statsData = Object.assign({}, inputStats);
  delete statsData["totalDetections"];
  var categoryKeys = Object.keys(statsData);

  const statsChartData = {
    labels: Object.keys(statsData).map((category) => {
      return chartCategoryLabels[category];
    }),
    datasets: [
      {
        label: "Detections",
        data: Object.values(statsData),
        backgroundColor: categoryKeys.map((category) => {
          return getChartColor(category);
        }),
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return statsChartData;
}
