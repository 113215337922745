

// import Hammer from "hammerjs";
import Hammer from "hammerjs";

import zoomPlugin from 'chartjs-plugin-zoom';

import { Line, Bar, Scatter } from "react-chartjs-2";
import { hourlyPoints, randomColor } from "../Utils";
import "chartjs-adapter-date-fns";

import { Paper } from "@material-ui/core";
import { Box } from "@material-ui/system";


const NUMBER_CFG = { count: 10, min: 0, max: 10 };
const data = {
  datasets: [
    {
      label: "Skiers",
      borderColor: randomColor(0.4),
      backgroundColor: randomColor(0.1),
      pointBorderColor: randomColor(0.7),
      pointBackgroundColor: randomColor(0.5),
      pointBorderWidth: 1,
      data: hourlyPoints(NUMBER_CFG)
    }
  ],
};


const scales = {
  x: {
    position: 'bottom',
    type: 'time',
    ticks: {
      autoSkip: true,
      autoSkipPadding: 50,
      maxRotation: 0,
      major: {
        enabled: true
      }
    },
    time: {
      
      displayFormats: {
        
        hour: 'HH:mm',
        minute: 'HH:mm',
        second: 'HH:mm:ss'
      }
    }
  },
  y: {
    position: 'left',
    ticks: {
      callback: (val, index, ticks) => index === 0 || index === ticks.length - 1 ? null : val,
      stepSize: 1
    },
    grid: {
      borderColor: randomColor(1),
      color: 'rgba( 0, 0, 0, 0.1)',
    },
    title: {
      display: true,
      text: "Detections",
    }
  },
};


const zoomOptions = {
  zoom: {

  pan: {
    enabled: true,
    mode: 'xy',
  }
}
};




const options = {
  responsive: true,
  scales: scales,
  plugins: {
    zoom: zoomOptions,
    title: {
      display: true,
      position: 'bottom'
      
    }
  },

};


const LineChart = ({ chartData }) => (
  <div>    
    <Box m={1.2} sx={{backgroundColor: "Red"}}>
      <Paper>
        <Line data={chartData} options={options} />
        {/* <Line data={data} options={options} plugins={[zoomPlugin]} /> */}
      </Paper>
    </Box>
  </div>
);

export default LineChart;
