import { useEffect } from "react";


import CssBaseline from "@material-ui/core/CssBaseline";


import {connect} from 'react-redux';
import * as actions from './store/authActions';

import Urls from "./Urls";

function App(props) {
  useEffect(() => {
    props.setAuthenticatedIfRequired();
  }, []);
  return (
    <div>
      <CssBaseline />
      {/* <Router>
        <Switch>
          <Route exact path="/login" component={SignIn} />
          <Route exact path="/machine_vision" component={Home} />
          <Route component={NotFound} />
        </Switch>
      </Router> */}

  <Urls {...props}/>

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null && typeof state.auth.token !== 'undefined',
    token: state.auth.token
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthenticatedIfRequired: () => dispatch(actions.authCheckState()),
    logout: () => dispatch(actions.authLogout()) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);