import axios from "axios";
import React from "react";
import * as settings from '../settings';
// import Cookies from 'js-cookie';

const client = axios.create({
  baseURL: settings.API_SERVER 
});


  export const getDataTimeRange = (startTime, endTime, classesFilter, responseHandler, errorHandler)=> {
    const headers= {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      
    };
    client
      .post("detection/", {
        start_time: startTime,
        end_time: endTime,
        classes_filters : classesFilter
      },
      {headers : headers}
      )
      .then((response) => {
        responseHandler(response);
        
      })
      .catch(error => {
        errorHandler(error)
     });
  }
